import React from 'react';
import { type QueryFunction } from 'react-query';

import { type SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { type Note } from '../';

/**
 * GET - /api/v1/eames/eames_notes/:id
 */

// HTTP

export type GetProjectNoteByIdXhr = Note;

export const getProjectNoteByIdXhr = (
  noteId: number,
  config: HttpBaseConfig = {}
): Promise<Note> => {
  return http
    .get<GetProjectNoteByIdXhr>(`/api/v1/eames/eames_notes/${noteId}`, config)
    .then((res) => res.data);
};

const GET_PROJECT_NOTE_BY_ID_QUERY_KEY_NAMESPACE = 'project/notes/id';

// Query Key

type GetProjectNoteByIdQueryKeyNamespace =
  typeof GET_PROJECT_NOTE_BY_ID_QUERY_KEY_NAMESPACE;

export type GetProjectNoteByIdQueryKey = [
  GetProjectNoteByIdQueryKeyNamespace,
  Note['id']
];

export const mapGetProjectNoteByIdQuery = (
  key: SkipFirst<GetProjectNoteByIdQueryKey>
): GetProjectNoteByIdQueryKey => [
  GET_PROJECT_NOTE_BY_ID_QUERY_KEY_NAMESPACE,
  ...key,
];

// QueryFn

export const getProjectNoteByIdQueryFn: QueryFunction<
  Note,
  GetProjectNoteByIdQueryKey
> = ({ queryKey: [_d, noteId], signal }) =>
  getProjectNoteByIdXhr(noteId, { signal });

// Query

export interface GetProjectNoteByIdQueryProps
  extends Omit<
    QueryProps<Note, Error, Note, GetProjectNoteByIdQueryKey>,
    'queryFn' | 'queryKey'
  > {
  noteId: number;
}

export type GetProjectNoteByIdQueryResult = Parameters<
  GetProjectNoteByIdQueryProps['children']
>[0];

export const GetProjectNoteByIdQuery = ({
  noteId,
  ...props
}: GetProjectNoteByIdQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={mapGetProjectNoteByIdQuery([noteId])}
      queryFn={getProjectNoteByIdQueryFn}
    />
  );
};
