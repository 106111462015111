import { useEffect, type ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { ReactQueryDevtools } from 'react-query/devtools';
import Head from 'next/head';
import { ToasterProvider } from '@shared_components/toaster/toaster';
import { AppStatusProvider } from 'js/components/AppStatus/AppStatusProvider';
import { PopupProvider } from 'js/components/partial/PopupProvider/PopupProvider';
import theme from 'js/theme';
import { QueryProvider } from 'js/api';
import { setUserToLocalStorage } from 'js/utils';
import { GetCurrentUserQuery } from 'js/api';
import AppStatus from 'js/components/AppStatus';
import MobileNotice from 'js/components/MobileNotice';
import IconSpinnerCircle from 'js/icons/SpinnerCircle';
import { AuthGuard } from './AuthGuard';
import 'react-datepicker/dist/react-datepicker.css';

export default function EamesLayout({ children }: { children: ReactNode }) {
  useEffect(() => {
    window.iPad = window.navigator.userAgent.match(/iPad/i) !== null;

    if (window.iPad) {
      window.document.body.classList.add('iPad');
    }

    if ('ontouchstart' in document.documentElement) {
      // hack to force touch devices to fire click events on non-button elements
      document.body.style.cursor = 'pointer';
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <link rel="icon" href={`/next_assets/brands/eames/favicon.png`} />
      </Head>
      <AppStatusProvider>
        <ToasterProvider>
          <QueryProvider>
            <PopupProvider>
              <ReactQueryDevtools initialIsOpen={false} />
              <AuthGuard>
                <GetCurrentUserQuery
                  options={{ onSuccess: setUserToLocalStorage }}
                >
                  {({ isLoading }) => {
                    if (isLoading)
                      return (
                        <div className="centered-button">
                          <IconSpinnerCircle />
                        </div>
                      );

                    return (
                      <div>
                        {/* @TODO Restore this */}
                        {/* <MobileNotice /> */}
                        <AppStatus />
                        {children}
                      </div>
                    );
                  }}
                </GetCurrentUserQuery>
              </AuthGuard>
            </PopupProvider>
          </QueryProvider>
        </ToasterProvider>
      </AppStatusProvider>
    </ThemeProvider>
  );
}
