import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import {
  Project,
  http,
  withMutation,
  type WithMutationPropsByMutation,
} from 'js/api/';
import {
  invalidateGetProjectNotesQueryData,
  type Note,
  type EamesNoteImagesUpdateParams,
} from '../';

/**
 * PUT - /api/v1/eames/eames_notes/:id
 */

// HTTP

export type EditProjectNoteBody = {
  body: string;
  imageParams?: EamesNoteImagesUpdateParams;
};

export interface UpdateProjectNoteXhrVariables {
  noteId: number;
  body: EditProjectNoteBody;
  projectId: Project['id'];
  progressCallBack?: (progress: number) => void;
}

export const updateProjectNoteXhr = ({
  noteId,
  body,
}: UpdateProjectNoteXhrVariables): Promise<Note> =>
  http
    .put<Note>(`/api/v1/eames/eames_notes/${noteId}`, body)
    .then((res) => res.data);

// MutationFn

type UpdateProjectNoteMutation = MutationFunction<
  Note,
  UpdateProjectNoteXhrVariables
>;

export const updateProjectNoteMutationFn: UpdateProjectNoteMutation = (
  variables
) => updateProjectNoteXhr(variables);

// With Mutation HOC

const withUpdateProjectNoteMutationPropKey = 'updateProjectNoteMutation';

export type WithUpdateProjectNoteMutationProps = WithMutationPropsByMutation<
  typeof withUpdateProjectNoteMutationPropKey,
  UpdateProjectNoteMutation
>;

export function withUpdateProjectNoteMutation<
  P extends WithUpdateProjectNoteMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateProjectNoteMutationProps>> {
  return withMutation(
    withUpdateProjectNoteMutationPropKey,
    updateProjectNoteMutationFn,
    (client) => ({
      onSuccess: async (_, { projectId }) => {
        await invalidateGetProjectNotesQueryData(client, [{ projectId }]);
      },
    })
  )(Component as ComponentType<WithUpdateProjectNoteMutationProps>);
}
