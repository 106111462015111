import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import {
  http,
  withMutation,
  type Project,
  type WithMutationPropsByMutation,
} from 'js/api/';
import { invalidateGetProjectNotesQueryData } from '../';

/**
 * DELETE - /api/v1/eames/eames_notes/:id
 */

// HTTP

interface DeleteProjectNoteXhrVariables {
  noteId: number;
  projectId: Project['id'];
}

export const deleteProjectNoteXhr = ({
  noteId,
  projectId,
}: DeleteProjectNoteXhrVariables): Promise<never> =>
  http
    .delete<never>(`/api/v1/eames/eames_notes/${noteId}`, {
      params: {
        projectId,
      },
    })
    .then((res) => res.data);

// MutationFn

type DeleteProjectNoteMutation = MutationFunction<
  never,
  DeleteProjectNoteXhrVariables
>;

export const deleteProjectNoteMutationFn: DeleteProjectNoteMutation = (
  variables
) => deleteProjectNoteXhr(variables);

// With Mutation HOC

const withDeleteProjectNoteMutationPropKey = 'deleteProjectNoteMutation';

export type WithDeleteProjectNoteMutationProps = WithMutationPropsByMutation<
  typeof withDeleteProjectNoteMutationPropKey,
  DeleteProjectNoteMutation
>;

export function withDeleteProjectNoteMutation<
  P extends WithDeleteProjectNoteMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithDeleteProjectNoteMutationProps>> {
  return withMutation(
    withDeleteProjectNoteMutationPropKey,
    deleteProjectNoteMutationFn,
    (client) => ({
      onSuccess: async (_, { projectId }) => {
        await invalidateGetProjectNotesQueryData(client, [{ projectId }]);
      },
    })
  )(Component as ComponentType<WithDeleteProjectNoteMutationProps>);
}
