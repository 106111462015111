import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import {
  http,
  withMutation,
  type Project,
  type WithMutationPropsByMutation,
} from 'js/api/';
import {
  invalidateGetProjectNotesQueryData,
  type Note,
  type EamesNoteImagesCreateParams,
} from '../';

/**
 * POST - /api/v1/eames/eames_notes
 */

// HTTP

export type CreateProjectNoteBody = {
  body: string;
  imageParams?: EamesNoteImagesCreateParams;
};

export interface CreateProjectNoteXhrVariables {
  body: CreateProjectNoteBody;
  projectId: Project['id'];
  progressCallBack?: (progress: number) => void;
}

export const createProjectNoteXhr = ({
  body,
  projectId,
  progressCallBack,
}: CreateProjectNoteXhrVariables): Promise<Note> => {
  return http
    .post<Note>(
      `/api/v1/eames/eames_notes`,
      {
        projectId: projectId,
        ...body,
      },
      {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          if (progressCallBack) {
            const progress = (progressEvent.loaded / progressEvent.total) * 50;
            progressCallBack(progress);
          }
        },
      }
    )
    .then((res) => res.data);
};

// MutationFn

type CreateProjectNoteMutation = MutationFunction<
  Note,
  CreateProjectNoteXhrVariables
>;

export const createProjectNoteMutationFn: CreateProjectNoteMutation = (
  variables
) => createProjectNoteXhr(variables);

// With Mutation HOC

const withCreateProjectNoteMutationPropKey = 'createProjectNoteMutation';

export type WithCreateProjectNoteMutationProps = WithMutationPropsByMutation<
  typeof withCreateProjectNoteMutationPropKey,
  CreateProjectNoteMutation
>;

export function withCreateProjectNoteMutation<
  P extends WithCreateProjectNoteMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateProjectNoteMutationProps>> {
  return withMutation(
    withCreateProjectNoteMutationPropKey,
    createProjectNoteMutationFn,
    (client) => ({
      onSuccess: async (_, { projectId }) => {
        await invalidateGetProjectNotesQueryData(client, [{ projectId }]);
      },
    })
  )(Component as ComponentType<WithCreateProjectNoteMutationProps>);
}
